import {
    createChildrenGroupRequest,
    deleteChildrenGroupByIdRequest,
    downloadChildrenGroupsListRequest,
    getChildrenGroupByIdRequest,
    getChildrenGroupsRequest,
    getKindergartensChildrenGroupsClearRequest,
    patchChildrenGroupByIdRequest
} from "@/helpers/api/childrenGroup";
import router from "@/router";
import {
    getKindergartensUserRequest,
    getUsersRequest
} from "@/helpers/api/user";
import {csvExport, csvNoResultExport} from "@/helpers/CsvHelper";
import {getAccessGroupsRequest} from "@/helpers/api/access-group";

const state = {
    childrenGroups: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    userGroupSearch: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    childrenGroupsInfo: {
        id: null,
        name: null,
        group: null,
        children_count: null,
        teachers_count: null,
        group_details: null,
        teachers: []
    },
    manageressList: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    // изменить на валидные поля, после того как Коля сделает их на беке
    childrenGroupCreateForm: {
        name: "",
        group: "",
        number: ""
    },
    // изменить на валидные поля, после того как Коля сделает их на беке
    manageressCreateForm: {
        teachers: null,
        childGroupId: null
    },
    editChildrenGroup: {
        number: {value: "", isEdit: false},
        name: {value: "", isEdit: false},
        group: {value: "", isEdit: false}
    },
    ChildGroupName: {
        nameGroup: ""
    }
};
const getters = {
    getChildrenGroups: state => state.childrenGroups,
    getManageressList: state => state.manageressList,
    getChildrenGroupsInfo: state => state.childrenGroupsInfo,
    getChildrenGroupsCreateForm: state => state.childrenGroupCreateForm,
    getManageressCreateForm: state => state.manageressCreateForm,
    getEditChildrenGroup: state => state.editChildrenGroup,
    getUserGroupSearch: state => state.userGroupSearch,
    getChildGroupName: state => state.ChildGroupName
};

const actions = {
    async fetchChildrenGroupByIdGetName(context, id) {
        const {query} = context.getters.getPageQuery;
        return new Promise((resolve, reject) => {
            getChildrenGroupByIdRequest({id, query})
                .then(response => {
                    context.commit("setNameChildrenGroup", {
                        fieldName: "nameGroup",
                        value: response.data.name
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async downloadChildrenGroupsList(context, header) {
        const {query} = context.getters.getPageQuery;
        const id = context.getters.getCurrentOrganization;
        const currentRole = context.getters.getCurrentRole;
        const currentUserId = context.getters.getUserId;
        return new Promise((resolve, reject) => {
            getChildrenGroupsRequest({
                query: {...query, limit: 5000, offset: null, kindergarten_org: id}
            })
                .then(response => {
                    if (currentRole == 21) {
                        const arrWithCurrentTeacher = response.data.results.filter(el =>
                            el.teachers.includes(currentUserId)
                        );
                        const data = {
                            ...response.data,
                            results: arrWithCurrentTeacher
                        };
                        csvExport(data, header, "ДетскиеГруппы");
                    } else {
                        csvExport(response.data, header, "ДетскиеГруппы");
                    }
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // async downloadKindergartensChildrenGroupsList(context, header) {
    //   const { query, id } = context.getters.getPageQuery;
    //   return new Promise((resolve, reject) => {
    //     getChildrenGroupsRequest({
    //       query: { ...query, limit: 5000, offset: null, kindergarten_org: id }
    //     })
    //       .then(response => {
    //         csvExport(response.data, header, "ДетскиеГруппы");
    //         resolve();
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    async downloadKindergartensChildrenGroupsList(context, header) {
        const { query, id } = context.getters.getPageQuery;
        const accessGroupsRequest = getAccessGroupsRequest({
            query: { ...query, limit: 5000, offset: null, organization: id }
        });
        const childrenGroupsRequest = getKindergartensChildrenGroupsClearRequest({
            query: { ...query, limit: 5000, offset: null, kindergarten_org: id },
            id:
                localStorage.getItem("organization") != 1
                    ? localStorage.getItem("organization")
                    : localStorage.getItem("KindergartenId")
        });
        return Promise.all([accessGroupsRequest, childrenGroupsRequest])
            .then(results => {
                const accessGroups = Array.from(results[0].data.results);
                const childrenGroups = Array.from(results[1].data.results);

                const merged = [];
                childrenGroups.forEach((item1) => {
                    accessGroups.forEach((item2) => {
                        if (item1.group === item2.id) {
                            delete item2.id
                            delete item2.id
                            const mergedItem = {...item1, ...item2};
                            merged.push(mergedItem);
                        }
                    });
                });

                return merged
            })
            .then(merged => {
                csvNoResultExport(merged, header, "ДетскиеГруппы");
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    },
    async downloadManageressList(context) {
        const {query, id} = context.getters.getPageQuery;
        return downloadChildrenGroupsListRequest({
            query: {...query, kindergarten_org: id}
        });
    },
    fetchChildrenGroupList(context) {
        const query = context.getters.getPageQuery.query;
        const id = context.getters.getCurrentOrganization;

        return new Promise((resolve, reject) => {
            getChildrenGroupsRequest({
                query: {...query, limit: 5000, offset: null, kindergarten_org: id}
            })
                .then(response => {
                    context.commit("setChildrenGroup", {
                        listName: "childrenGroups",
                        childrenGroup: response.data
                    });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async deleteChildrenGroup(context, {id}) {
        router.go(-1);
        return deleteChildrenGroupByIdRequest({id});
    },
    async editChildrenGroup(context, {id}) {
        const editForm = context.getters.getEditChildrenGroup;
        let editedData = {};
        for (let key in editForm) {
            if (editForm[key].isEdit) {
                editedData[key] = editForm[key].value;
            }
        }
        const editProm = patchChildrenGroupByIdRequest({
            id,
            data: editedData
        }).then(() => {
            context.commit("clearEditChildrenGroup", {
                listName: "editChildrenGroup"
            });
        });
        editProm.then(() => {
            router.go(-1);
        });
        return editProm;
    },

    async prepareEditChildrenGroup(context, {id}) {
        const editChildrenGroup = (await getChildrenGroupByIdRequest({id})).data;
        context.commit("prepareEditChildrenGroup", editChildrenGroup);
    },
    fetchChildrenGroupByKindergartens(context) {
        const {query, id} = context.getters.getPageQuery;
        const ordering = context.getters.getOrdering;

        return new Promise((resolve, reject) => {
            getKindergartensChildrenGroupsClearRequest({
                query: {...query, limit: 5000, ordering, kindergarten_org: id},
                id:
                    localStorage.getItem("organization") != 1
                        ? localStorage.getItem("organization")
                        : localStorage.getItem("KindergartenId")
            })
                .then(response => {
                    context.commit("setChildrenGroup", {
                        listName: "childrenGroups",
                        childrenGroup: response.data
                    });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    fetchChildrenGroupByIdList(context) {
        const {query} = context.getters.getPageQuery;
        const ordering = context.getters.getOrdering;

        return new Promise((resolve, reject) => {
            getChildrenGroupsRequest({
                query: {
                    ...query,
                    limit: 5000,
                    ordering
                    // kindergarten_org: localStorage.getItem("KindergartenId")
                }
            })
                .then(response => {
                    context.commit("setChildrenGroup", {
                        listName: "childrenGroups",
                        childrenGroup: response.data
                    });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    fetchChildrenGroupByIdListForChildrenGroup(context) {
        const {query} = context.getters.getPageQuery;
        const ordering = context.getters.getOrdering;

        return new Promise((resolve, reject) => {
            getChildrenGroupsRequest({
                query: {
                    ...query,
                    limit: 5000,
                    ordering,
                    kindergarten_org: localStorage.getItem("KindergartenId")
                }
            })
                .then(response => {
                    context.commit("setChildrenGroup", {
                        listName: "childrenGroups",
                        childrenGroup: response.data
                    });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async downloadKindergartensManageressList(context, header) {
        const {id} = context.getters.getPageQuery;
        return new Promise((resolve, reject) => {
            getUsersRequest({query: {children_groups: id}})
                .then(async response => {
                    csvExport(response.data, header, "Воспитатели");
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async fetchManageressById(context) {
        const {id, query} = context.getters.getPageQuery;
        const ordering = context.getters.getOrdering;
        return new Promise((resolve, reject) => {
            getKindergartensUserRequest({
                query: {children_groups: id, ...query, ordering},
                id:
                    localStorage.getItem("organization") != 1
                        ? localStorage.getItem("organization")
                        : localStorage.getItem("KindergartenId")
            })
                .then(async response => {
                    context.commit("setManageress", {
                        listName: "manageressList",
                        manageress: response.data
                    });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async fetchChildrenGroupInfo(context) {
        const {id} = context.getters.getPageQuery;
        return new Promise((resolve, reject) => {
            getChildrenGroupByIdRequest({id})
                .then(response => {
                    context.commit("setChildrenGroup", {
                        listName: "childrenGroupsInfo",
                        childrenGroup: response.data
                    });

                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async createChildrenGroups(context) {
        const {name, group, number} = context.getters.getChildrenGroupsCreateForm;
        const createResult = createChildrenGroupRequest({
            data: {
                name,
                group: group ? group : localStorage.getItem("UserGroup"),
                number
            }
        });
        createResult.then(response => {
            router.replace(`childrenGroups/${response.data.id}`);
            context.commit("clearCreateChildrenGroupForm", {
                fieldName: "childrenGroupCreateForm"
            });
        });
        return createResult;
    },
    async createChildrenGroupsFromChildren(context) {
        const createResult = createChildrenGroupRequest({
            data: {
                name: localStorage.getItem("UserGroupName"),
                group: localStorage.getItem("UserGroup"),
                number: localStorage.getItem("UserGroupNumber")
            }
        });
        createResult.then(response => {
            router.replace(`childrenGroups/${response.data.id}`);
            context.commit("clearCreateChildrenGroupForm", {
                fieldName: "childrenGroupCreateForm"
            });
        });
        return createResult;
    },
    async createManageress(context) {
        const creatForm = context.getters.getManageressCreateForm;
        const teachersArr = (
            await getChildrenGroupByIdRequest({
                id: creatForm.childGroupId
            })
        ).data;

        const createResult = patchChildrenGroupByIdRequest({
            id: creatForm.childGroupId,
            data: {teachers: [...teachersArr.teachers, creatForm.teachers]}
        });
        createResult.then(() => {
            router.go(-1);
            context.commit("clearCreateForm", {
                fieldName: "manageressCreateForm"
            });
        });
        return createResult;
    },
    async clearChildrenGroup(context) {
        context.commit("clearChildrenGroup", {
            listName: "childrenGroups"
        });
        return Promise.resolve();
    },
    // удаление воспитателя из детской группы
    async deleteManageressFromGroup(context, {id, childrenGroupsId}) {
        //получили массив воспитаталей в группе
        const teachersArr = (
            await getChildrenGroupByIdRequest({
                id: childrenGroupsId
            })
        ).data.teachers;

        // оставляем массив без id воспистателя
        let data = {
            teachers: teachersArr.filter(el => el != id)
        };
        // делаем псевдоудаление
        await patchChildrenGroupByIdRequest({
            id: childrenGroupsId,
            data
        });

        return;
    },

    async getTeacherId(context, {teacher}) {
        return new Promise((resolve, reject) => {
            getUsersRequest({
                query: {
                    search: teacher
                }
            })
                .then(response => {
                    resolve(response.data.results[0].id);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

const mutations = {
    clearEditChildrenGroup(state, {listName}) {
        state[listName].name = {
            value: "",
            isEdit: false
        };
    },
    setNameChildrenGroup(state, {fieldName, value}) {
        state.ChildGroupName[fieldName] = value;
    },
    setManageress(state, {listName, manageress}) {
        state[listName] = manageress;
    },
    setChildrenGroup(state, {listName, childrenGroup}) {
        state[listName] = childrenGroup;
    },
    clearChildrenGroup(state, {listName}) {
        state[listName].results = [];
    },
    clearCreateForm(state, {fieldName}) {
        state[fieldName] = {};
    },
    clearCreateChildrenGroupForm(state) {
        state.childrenGroupCreateForm = {
            name: null,
            group: null,
            number: null
        };
    },
    setChildrenGroupsCreateForm(state, {fieldName, value}) {
        state.childrenGroupCreateForm[fieldName] = value;
    },
    setManageressCreateForm(state, {fieldName, value}) {
        state.manageressCreateForm[fieldName] = value;
    },
    prepareEditChildrenGroup(state, editChildrenGroup) {
        Object.keys(state.editChildrenGroup).forEach(key => {
            state.editChildrenGroup[key].value = editChildrenGroup[key];
            state.editChildrenGroup[key].isEdit = false;
        });
    },
    setUserGroupSearch(state, {listName, group}) {
        state[listName] = group;
    },
    changeEditChildrenGroup(state, {fieldName, value}) {
        state.editChildrenGroup[fieldName].value = value;
        state.editChildrenGroup[fieldName].isEdit = true;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
