import Vue from "vue";
import router from "@/router";
import querySetter from "@/helpers/QuerySetterHelper";

const state = {
  pageQuery: {
    id: null,
    query: {
      //TODO filling possible queries
    }
  },
  preQuery: {
    query: {}
  },
  routeQuery: {
    routes: {}
  }
};

const getters = {
  getPageQuery: (state, getters) => {
    //40

    if ([21, 31, 30].includes(getters.getCurrentRole)) {
      return {
        id: state.pageQuery.id,
        query: querySetter.addOrgId(
          getters.getCurrentOrganization,
          state.pageQuery.query
        )
      };
    }
    if ([60].includes(getters.getCurrentRole)) {
      return {
        id: state.pageQuery.id,
        query: querySetter.addDillerId(
          getters.getCurrentOrganization,
          state.pageQuery.query
        )
      };
    }
    if ([40, 50].includes(getters.getCurrentRole)) {
      return {
        id: state.pageQuery.id,
        query: querySetter.addServiceOrgId(
          getters.getCurrentOrganization,
          state.pageQuery.query
        )
      };
    }
    return state.pageQuery;
  },
  getPreQuery: state => state.preQuery,
  getUrlQueris: state => state.pageQuery.query,
  getRouteQuery: state => state.routeQuery
};

const actions = {
  async findResult(context) {
    const currentQuery = context.getters.getPageQuery.query;
    const preQuery = context.getters.getPreQuery.query;
    // eslint-disable-next-line no-unused-vars
    const { offset, ...newQuery } = { ...currentQuery, ...preQuery };
    context.commit("setSearchQuery", {
      query: newQuery
    });
    if (newQuery) {
      await router.push({ ...router.currentRoute, query: newQuery });
    }
    context.commit("clearQuery", { queryName: "preQuery" });
  }
};

const mutations = {
  setSearchQuery(state, { query, id = null }) {
    let resultQuery = querySetter.dealerHelper(query);
    resultQuery = querySetter.organizationHelper(resultQuery);
    resultQuery = querySetter.serviceOrganizationHelper(resultQuery);
    state.pageQuery.query = resultQuery;
    state.pageQuery.id = id;
  },
  setPreQuery(state, { key, value }) {
    Vue.set(state.preQuery.query, key, value);
  },
  clearQuery(state, { queryName }) {
    state[queryName].query = {};
  },
  setRouterQuery(state, { routes }) {
    state.routeQuery.routes = routes;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
