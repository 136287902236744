import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getAccessesRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: `/accesses/`,
      query: {
        ...query,
        "optional-fields": ["object_details", "user_details", "card_details"]
      }
    })
  );
}
