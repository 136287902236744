import {
  getCardsRequest,
  getCardsListRequests,
  uploadCardFileRequest,
  deleteCardByIdRequest,
  deleteArrCardRequest,
  userRegisterCardRequest,
  userLostCardRequest,
  getCardByIdRequest,
  patchCardCodeByIdRequest,
  restoreCardByIdRequest,
  getCardsServiceRequest,
  getAllCardsListRequests,
  createCardListRequest,
  getkindergartensCardsRequest,
  getKindergartensCardByIdRequest
} from "@/helpers/api/cards";
import Vue from "vue";
import router from "@/router";
import { getKindergartenByIdRequest } from "@/helpers/api/kindergartens";
import { csvExport } from "@/helpers/CsvHelper";

const state = {
  pageCardsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  code: {
    code: null
  },
  createCardFileForm: {
    file: null,
    object: null,
    disable_activation_period_expires: false
  },
  createCardManualForm: {
    cardNumber: "",
    cardNumbers: [],
    disable_activation_period_expires: false,
    object: null
  },
  organizationCardsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};
const getters = {
  getPageCardsList: state => state.pageCardsList,
  getCreateCardFileForm: state => state.createCardFileForm,
  getCreateCardManualForm: state => state.createCardManualForm,
  getOrganizationCardsList: state => state.organizationCardsList
};

const actions = {
  fetchPageCardsList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getCardsRequest({
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setCardsList", {
            listName: "pageCardsList",
            cards: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPageCardsIdList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    const id = context.getters.getPageQuery.id;
    return new Promise((resolve, reject) => {
      getCardByIdRequest({
        id,
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setCardsList", {
            listName: "pageCardsList",
            cards: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchKindergartensPageCardsIdList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    const cadrId = context.getters.getPageQuery.id;
    const id =
      context.getters.getKindergartenId ||
      context.getters.getCurrentOrganization;
    return new Promise((resolve, reject) => {
      getKindergartensCardByIdRequest({
        cadrId,
        id,
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setCardsList", {
            listName: "pageCardsList",
            cards: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  downloadPageCardsList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getCardsRequest({ query: { ...query, limit: 5000, offset: null } })
        .then(response => {
          csvExport(response.data, header, "Карты");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
    // return downloadCardsListRequest({ query });
  },

  downloadReportsAllCardsList() {
    return new Promise((resolve, reject) => {
      getAllCardsListRequests({
        query: { offset: null }
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  downloadReportsCardsList(context, id) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getCardsListRequests({
        query: { ...query, offset: null, id: id }
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadOrganizationCardsList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getkindergartensCardsRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null
        },
        id:
          localStorage.getItem("organization") != 1
            ? localStorage.getItem("organization")
            : localStorage.getItem("KindergartenId")
      })
        .then(response => {
          csvExport(response.data, header, "Карты");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchKindergartensCardsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;

    return new Promise((resolve, reject) => {
      getkindergartensCardsRequest({
        query: { ...query, ordering },
        id: id || context.getters.getPageQuery.query.kindergarten
      })
        .then(response => {
          context.commit("setCardsList", {
            listName: "organizationCardsList",
            cards: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationCardsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;

    const kindergarten = (await getKindergartenByIdRequest({ id })).data;
    return new Promise((resolve, reject) => {
      getCardsRequest({
        query: { ...query, ordering, object: kindergarten?.object?.id }
      })
        .then(response => {
          context.commit("setCardsList", {
            listName: "organizationCardsList",
            cards: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectCardsList(context) {
    const { query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    let id = context.getters.getObjectInfo.results.organization;

    const kindergarten = (await getKindergartenByIdRequest({ id })).data;

    return new Promise((resolve, reject) => {
      getCardsServiceRequest({
        query: { ...query, ordering, object: kindergarten?.object?.id }
      })
        .then(response => {
          context.commit("setCardsList", {
            listName: "organizationCardsList",
            cards: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearCardsPage(context) {
    context.commit("clearCardsList", {
      listName: "pageCardsList"
    });
    return Promise.resolve();
  },
  async uploadCards(context) {
    const uploadForm = context.getters.getCreateCardFileForm;
    const formData = new FormData();
    formData.append("file", uploadForm.file);
    formData.append("object", uploadForm.object);
    formData.append(
      "disable_activation_period_expires",
      uploadForm.disable_activation_period_expires
    );
    const createResult = uploadCardFileRequest({
      data: formData
    });

    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateCardForm");
    });
    return createResult;
  },
  async editCard(context) {
    const cardId = context.state.pageCardsList.id;
    const editCard = patchCardCodeByIdRequest({
      id: cardId,
      data: { code: context.state.pageCardsList.code }
    });
    editCard.then(() => {
      router.go(-1);
      context.commit("clearCreateCardForm");
    });
    return editCard;
  },
  async createCards(context) {
    const createForm = context.getters.getCreateCardManualForm;
    const createResult = createCardListRequest({
      data: {
        card_list: createForm.cardNumbers,
        object: createForm.object,
        disable_activation_period_expires:
          createForm.disable_activation_period_expires
      }
    });

    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateCardForm");
    });
    return createResult;
  },

  async deleteCards(context, { id }) {
    const prom = deleteCardByIdRequest({ id: id });
    return prom;
  },

  async deleteArrCards(context, { data }) {
    const prom = await deleteArrCardRequest({ data });
    return prom;
  },

  async addExistingCardByUser(context, { number, objId }) {
    const data = {
      object: objId,
      code: number
    };
    return new Promise((resolve, reject) => {
      userRegisterCardRequest({ data })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async lostCardUser(context, { id }) {
    return new Promise((resolve, reject) => {
      userLostCardRequest({ id })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //востановление карты из удаленных
  async restoreCard(context, { id }) {
    return new Promise((resolve, reject) => {
      restoreCardByIdRequest({ id })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  changeEditCards(state, { fieldName, value }) {
    state.pageCardsList[fieldName] = value;
  },
  clearCreateCardForm(state) {
    state.createCardFileForm = {
      file: null,
      object: null,
      disable_activation_period_expires: false
    };
    state.createCardManualForm = {
      cardNumber: "",
      cardNumbers: [],
      object: null,
      disable_activation_period_expires: false
    };
  },
  setCreateCardFileForm(state, { fieldName, value }) {
    Vue.set(state.createCardFileForm, fieldName, value);
  },
  setCreateCardManualForm(state, { fieldName, value }) {
    Vue.set(state.createCardManualForm, fieldName, value);
  },
  deleteCardNumber(state, { id }) {
    state.createCardManualForm.cardNumbers.splice(id, 1);
  },
  addCardNumber(state, { number }) {
    state.createCardManualForm.cardNumbers.splice(0, 0, number);
  },
  setCardsList(state, { listName, cards }) {
    state[listName] = cards;
  },
  clearCardsList(state, { listName }) {
    state[listName].results = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
