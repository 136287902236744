import {
  createKindergartensChildrenRequest,
  deleteChildrenByRequest,
  deleteChildrenParentByIdRequest,
  getChildrenByRequest,
  getChildrenRequest,
  getkindergartensChildrenGroupsRequest,
  getkindergartensChildrenRequest,
  patchChildrenByRequest,
  uploadChildrensFileRequest
} from "@/helpers/api/Children/Children";
import { deleteArrCardRequest } from "@/helpers/api/cards";
import router from "@/router";
import {
  // createUserRequest,
  // getOrganizationUserByIdRequest,
  getOrganizationUserRequest,
  // postAddUserToGroupRequest,
  // getUserByIdRequest,
  postDeleteUserFromGroupRequest,
  getKindergartensUserRequest,
  postAddUserKindergartensToGroupRequest,
  getUserKindergartensByIdRequest
} from "@/helpers/api/user";
import {
  getChildrenGroupsRequest,
  postTransferChildrenRequest
} from "@/helpers/api/childrenGroup";
import _ from "lodash";
import Axios from "axios";
import { getChildrenGroupByIdRequest } from "@/helpers/api/childrenGroup";
import { csvExport } from "@/helpers/CsvHelper";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import {
  checkSameParent,
  getCardCodeId
  // checkIsSameGroup
} from "@/helpers/functions";

const state = {
  KindergartensChildren: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  childrenInfos: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  createChildren: {
    name: null,
    children_group: null,
    number: null,
    parents: null
  },
  createParentsForm: {
    phone_number: "",
    group: null,
    user: "",
    comments: "",
    selectedId: null
  },
  editChildren: {
    name: { value: "", isEdit: false },
    comments: { value: "", isEdit: false },
    children_group: { value: "", isEdit: false },
    number: { value: "", isEdit: false },
    parents: { value: "", isEdit: false }
  },
  parentsList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  parentInfos: {
    results: null
  }
};
const getters = {
  getKindergartensChildren: state => state.KindergartensChildren,
  getChildrenInfos: state => state.childrenInfos,
  getCreateChildren: state => state.createChildren,
  getCreateParentsForm: state => state.createParentsForm,
  getEditChildren: state => state.editChildren,
  getParentsList: state => state.parentsList,
  getParentInfos: state => state.parentInfos
};

const actions = {
  async downloadKindergartensChildrenList(context, header) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getChildrenRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null,
          children_group__group__organization: id
        }
      })
        .then(response => {
          csvExport(response.data, header, "Воспитанники");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadChildrenList(context, header) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getChildrenRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null,
          children_group: id
        }
      })
        .then(response => {
          csvExport(response.data, header, "Воспитанники");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadChildrenUserGroupsList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getkindergartensChildrenRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null
        },
        id:
          localStorage.getItem("organization") != 1
            ? localStorage.getItem("organization")
            : localStorage.getItem("KindergartenId")
      })
        .then(response => {
          csvExport(response.data, header, "Воспитанники");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async downloadParentsList(context, { kindergartnerId, header }) {
    const { query, id } = context.getters.getPageQuery;
    const currentOrganizationId = context.getters.getCurrentOrganization;
    return new Promise((resolve, reject) => {
      getOrganizationUserRequest({
        query: { ...query, children: id },
        id: kindergartnerId || currentOrganizationId
      })
        .then(response => {
          csvExport(response.data, header, "Опекуны");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchKindergartensChildren(context) {
    const query = context.getters.getPageQuery.query;
    return new Promise((resolve, reject) => {
      getChildrenRequest({ query })
        .then(response => {
          context.commit("setKindergartensChildren", {
            listName: "KindergartensChildren",
            children: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchChildrenByChildrenGroup(context, param) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;

    return new Promise((resolve, reject) => {
      getkindergartensChildrenGroupsRequest({
        query: {
          ...query,
          children_group: id,
          ordering,
          children_group__group__organization: param
        }
      })
        .then(response => {
          context.commit("setKindergartensChildren", {
            listName: "KindergartensChildren",
            children: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async createChildren(context, { parentsList }) {
    const { name, children_group, number } = context.getters.getCreateChildren;

    const existParents = parentsList.filter(el => el.id > 0);
    const existParentsId = existParents.map(el => el.id);
    const newParents = parentsList.filter(el => el.id < 0);
    const newParentsId = [];

    //id группы пользователей
    const accessGroup = (
      await getChildrenGroupByIdRequest({ id: children_group })
    ).data.group;

    return new Promise((resolve, reject) => {
      createKindergartensChildrenRequest({
        id:
          localStorage.getItem("organization") != 1
            ? localStorage.getItem("organization")
            : localStorage.getItem("KindergartenId"),
        data: {
          name,
          children_group,
          number,
          parentsList
        }
      })
        .then(async response => {
          // if (existParents.length > 0) {
          //   for (const parent of existParents) {
          //     // если родителя нет в группе пользователя
          //     if (!checkIsSameGroup(parent.access_groups, accessGroup)) {
          //       await postAddUserToGroupRequest({
          //         id: parent.id,
          //         data: { group: accessGroup }
          //       });
          //     }
          //   }
          // }
          if (newParents.length > 0) {
            for (const parent of newParents) {
              const dataForm = {
                additional_fields: [
                  {
                    id: localStorage.getItem("UserFieldId"),
                    value: parent.user || "-"
                  },
                  {
                    id: localStorage.getItem("CommentsFieldId"),
                    value: parent.comments || "-"
                  }
                ],
                phone_number: parent.phone_number,
                group: accessGroup
              };
              const userId = (
                await postAddUserKindergartensToGroupRequest({
                  id:
                    localStorage.getItem("organization") != 1
                      ? localStorage.getItem("organization")
                      : localStorage.getItem("KindergartenId"),
                  data: dataForm
                })
              ).data.id;
              newParentsId.push(userId);
            }
          }

          await Axios.patch(`/children/${response.data.id}/`, {
            parents: [...existParentsId, ...newParentsId]
          });

          context.commit("clearCreateFormChildren", {
            fieldName: "createChildren"
          });
          context.commit("clearCreateFormChildren", {
            fieldName: "createChildren"
          });
          return response;
        })
        .then(response => {
          resolve(response.data.id);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchChildrenByKindergartens(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getkindergartensChildrenRequest({
        query: { ...query, children_group__group__organization: id, ordering }
      })
        .then(response => {
          context.commit("setKindergartensChildren", {
            listName: "KindergartensChildren",
            children: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchChildrenGroupObjectOrganizationChildren(context) {
    const { query } = context.getters.getPageQuery;
    const id = context.getters.getCurrentOrganization;
    return new Promise((resolve, reject) => {
      getChildrenRequest({
        query: { ...query, children_group__group__organization: id }
      })
        .then(response => {
          context.commit("setKindergartensChildren", {
            listName: "KindergartensChildren",
            children: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async transferChildren(context, { ids }) {
    let children_group = context.getters.getCreateChildren.children_group;
    await postTransferChildrenRequest({
      id: ids,
      kinderId:
        localStorage.getItem("organization") != 1
          ? localStorage.getItem("organization")
          : localStorage.getItem("KindergartenId"),
      data: { children_group: children_group }
    });
  },

  async deleteChildren(context, { id }) {
    await deleteChildrenByRequest({ id }); // Удаляем ребенка
    router.go(-1);
  },

  async fetchParentById(context, kindergartnerId) {
    const { id, query } = context.getters.getPageQuery;
    const orgId = context.getters.getCurrentOrganization;
    return new Promise((resolve, reject) => {
      getUserKindergartensByIdRequest({
        query,
        kindergardens_id: kindergartnerId || orgId,
        user_id: id
      })
        .then(response => {
          context.commit("setParentInfos", {
            fieldName: "parentInfos",
            parentInfos: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchParentsList(context, kindergartnerId) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    const currentOrganizationId = context.getters.getCurrentOrganization;
    const usersOrganization = ( // getKindergartensUserRequest
      await getKindergartensUserRequest({
        query: { ...query, children: id },
        id: kindergartnerId || currentOrganizationId,
        ordering
      })
    ).data;
    context.commit("setParentsList", {
      listName: "parentsList",
      parents: usersOrganization
    });
  },
  async fetchChildrenById(context) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getChildrenByRequest({ query, id })
        .then(response => {
          context.commit("setKindergartensChildren", {
            listName: "childrenInfos",
            children: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // async createChildren(context, { parentsList }) {
  //   const {
  //     name,
  //     children_group,
  //     number
  //   } = context.getters.getCreateChildren;
  //
  //   const existParents = parentsList.filter(el => el.id > 0);
  //   const existParentsId = existParents.map(el => el.id);
  //   const newParents = parentsList.filter(el => el.id < 0);
  //   const newParentsId = [];
  //
  //   //id группы пользователей
  //   const accessGroup = (
  //     await getChildrenGroupByIdRequest({ id: children_group })
  //   ).data.group;
  //
  //   return new Promise((resolve, reject) => {
  //     createKindergartensChildrenRequest({
  //       id: context.getters.getKindergartenId,
  //       data: {
  //         name,
  //         children_group,
  //         number,
  //         parentsList
  //       }
  //     })
  //       .then(async response => {
  //         // if (existParents.length > 0) {
  //         //   for (const parent of existParents) {
  //         //     // если родителя нет в группе пользователя
  //         //     if (!checkIsSameGroup(parent.access_groups, accessGroup)) {
  //         //       await postAddUserToGroupRequest({
  //         //         id: parent.id,
  //         //         data: { group: accessGroup }
  //         //       });
  //         //     }
  //         //   }
  //         // }
  //         if (newParents.length > 0) {
  //           for (const parent of newParents) {
  //             const dataForm = {
  //               additional_fields: [
  //                 {
  //                   id: context.getters.getDealerInfo.results.additional_fields[0].id,
  //                   value: parent.user || "-",
  //                 },
  //                 {
  //                   id: context.getters.getDealerInfo.results.additional_fields[4].id,
  //                   value: parent.comments || "-",
  //                 }
  //               ],
  //               phone_number: parent.phone_number,
  //               group: accessGroup,
  //             };
  //             const userId = (await postAddUserKindergtensToGroupRequest({ id: context.getters.getKindergartenId,data: dataForm })).data
  //               .id;
  //             newParentsId.push(userId);
  //           }
  //         }

  // async createParentsForm(context, createdChildrenId) {
  //   const selectedId = context.getters.getCreateParentsForm.selectedId;
  //   const dataForm = context.getters.getCreateParentsForm;
  //   dataForm.phone_number = dataForm.phone_number
  //     .replace(/[^\d+]/g, "")
  //     .slice(0, 13);
  //   const child = (
  //     await getChildrenByRequest({
  //       id: createdChildrenId,
  //       query: {}
  //     })
  //   ).data;
  //   const getParents = child.parents;
  //   const childrenInfo = (
  //     await getChildrenGroupByIdRequest({
  //       id: child.children_group.id
  //     })
  //   ).data;
  //   const accessGroup = childrenInfo.group;
  //   dataForm.group = accessGroup;
  //
  //   //если существующий пользователь
  //   if (selectedId) {
  //     const parentInfo = (
  //       await getUserByIdRequest({
  //         id: selectedId
  //       })
  //     ).data;
  //
  //     // если родителя нет в группе пользователя
  //     if (!checkIsSameGroup(parentInfo.access_groups, accessGroup)) {
  //       await postAddUserToGroupRequest({
  //         id: selectedId,
  //         data: { group: accessGroup }
  //       });
  //     }
  //
  //     await patchChildrenByRequest({
  //       id: createdChildrenId,
  //       data: { parents: [...getParents, selectedId] }
  //     }).then(() => {
  //       context.commit("clearCreateParentsForm", {
  //         listName: "createParentsForm"
  //       });
  //     });
  //   } else {
  //     const userId = (await createUserRequest({ data: dataForm })).data.id;
  //     if (userId) {
  //       await patchChildrenByRequest({
  //         id: createdChildrenId,
  //         data: { parents: [...getParents, userId] }
  //       }).then(() => {
  //         context.commit("clearCreateFormChildren", {
  //           fieldName: "createChildren"
  //         });
  //         context.commit("clearCreateParentsForm", {
  //           listName: "createParentsForm"
  //         });
  //       });
  //     }
  //   }
  // },

  async createParentsForm(context, createdChildrenId) {
    // const dataForm = context.getters.getCreateParentsForm;

    // dataForm.phone_number = dataForm.phone_number
    //   .replace(/[^\d+]/g, "")
    //   .slice(0, 13);
    const child = (
      await getChildrenByRequest({
        id: createdChildrenId,
        query: {}
      })
    ).data;
    const getParents = child.parents;
    const childrenInfo = (
      await getChildrenGroupByIdRequest({
        id: child.children_group.id
      })
    ).data;
    const accessGroup = childrenInfo.group;

    const dataForm1 = {
      additional_fields: [
        {
          id: localStorage.getItem("UserFieldId"),
          value: context.getters.getCreateParentsForm.user
        },
        {
          id: localStorage.getItem("CommentsFieldId"),
          value: context.getters.getCreateParentsForm.comments
        }
      ],

      phone_number: context.getters.getCreateParentsForm.phone_number,
      group: accessGroup
    };

    const userId = (
      await postAddUserKindergartensToGroupRequest({
        id:
          localStorage.getItem("organization") != 1
            ? localStorage.getItem("organization")
            : localStorage.getItem("KindergartenId"),
        data: dataForm1
      })
    ).data.id;

    if (userId) {
      await patchChildrenByRequest({
        id: createdChildrenId,
        data: { parents: [...getParents, userId] }
      }).then(() => {
        context.commit("clearCreateFormChildren", {
          fieldName: "createChildren"
        });
        context.commit("clearCreateParentsForm", {
          listName: "createParentsForm"
        });
      });
    }
  },
  async clearKindergartensChildren(context) {
    context.commit("clearKindergartensList", {
      listName: "KindergartensChildren"
    });
    return Promise.resolve();
  },
  async editChildren(context, { id }) {
    const editForm = context.getters.getEditChildren;
    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    const editProm = patchChildrenByRequest({ id, data: editedData });
    editProm.then(() => {
      router.go(-1);
    });
    return editProm;
  },
  async prepareEditChildren(context, { id }) {
    const editChildren = (await getChildrenByRequest({ id })).data;
    editChildren.children_group = editChildren.children_group.id;
    context.commit("prepareEditChildren", editChildren);
    return editChildren;
  },

  //удаление родителя у ребенка
  async deleteParent(context) {
    const { routes } = context.getters.getRouteQuery;
    const childId = routes.childrenId; // id ребенка
    const parentId = routes.id; // id Родителя
    const parentInfos = context.getters.getParentInfos; // подробная инфа про родителя
    let kindergartens_pk = context.getters.getCurrentOrganization; // id организации
    if ([60, 70].includes(context.getters.getCurrentRole)) {
      // если это админы компаний
      kindergartens_pk = routes.kindergartenId;
    }
    const childGroupId = (
      await getChildrenByRequest({ query: {}, id: childId })
    ).data.children_group.id; // id детской группы
    const сhildrenRequest = (
      await getChildrenRequest({ query: { children_group: childGroupId } })
    ).data.results; // получили список всех детей в детской группе
    const childrensInGroup = сhildrenRequest.filter(el => el.id != childId); // список всех детей в этой детской группе, кроме текущего ребенка
    const childInfo = сhildrenRequest.filter(el => el.id == childId)[0]; // подробная инфа про ребенка
    const parentList = childInfo.parents.filter(el => el != parentId); // список родителей без текущего -> проверка на наличие других родителей
    const group_id = (
      await getChildrenGroupsRequest({
        query: { kindergarten_org: kindergartens_pk }
      })
    ).data.results.filter(el => el.id == childGroupId)[0].group; // группа пользователей в которую входит родитель

    //проверяем есть ли еще ребенок в этой группе с таким же родителем
    if (checkSameParent(childrensInGroup, parentId)) {
      if (childInfo.parents.length <= 1) {
        return context.dispatch(
          "warningNotification",
          "Вы не можете удалить единственного родителя у ребенка, для начала добавьте другого родителя!"
        );
      } else {
        // открепляем родителя
        await patchChildrenByRequest({
          id: childId,
          data: { parents: parentList }
        });
      }
    } else {
      // еcли родитель есть в других группах
      if (parentInfos.access_groups.length > 1) {
        if (childInfo.parents.length <= 1) {
          return context.dispatch(
            "warningNotification",
            "Вы не можете удалить единственного родителя у ребенка, для начала добавьте другого родителя!"
          );
        } else {
          // открепляем родителя
          await patchChildrenByRequest({
            id: childId,
            data: { parents: parentList }
          });
          // удаляем из группы пользователей
          await postDeleteUserFromGroupRequest({
            id: parentId,
            data: { group: group_id }
          });
        }
      }
      // полностью удаляем родителей из ДС
      await deleteChildrenParentByIdRequest({
        children_id: context.getters.getChildrenInfos.id,
        kindergartens_id: kindergartens_pk,
        data: { parent: parentId }
      });
      // удаляем также его физическую карту
      const arrCards = parent => {
        return _.flattenDeep(getCardCodeId(parent.cards));
      };
      if (arrCards(parentInfos).length > 0) {
        await deleteArrCardRequest({
          data: { cards_to_delete: arrCards(parentInfos) }
        }); // Удаляем карты списком
      }
    }

    return context.dispatch(
      "successNotification",
      actionMessage.remove(successMessageDictionary.parent)
    );
  },

  //создание детей через файл
  async uploadChildrens(context, file) {
    const uploadForm = context.getters.getCreateChildren;
    const { routes } = context.getters.getRouteQuery;
    const organization_pk = [31, 21].includes(context.getters.getCurrentRole)
      ? context.getters.getCurrentOrganization
      : routes.kindergartenId;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("children_group_id", uploadForm.children_group);
    formData.append("organization_pk", organization_pk);
    const createResult = uploadChildrensFileRequest({
      data: formData,
      organization_pk
    });

    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateFormChildren", {
        fieldName: "createChildren"
      });
    });
    return createResult;
  }
};

const mutations = {
  setParentsCreateForm(state, { fieldName, value }) {
    state.createParentsForm[fieldName] = value;
  },
  setKindergartensChildren(state, { listName, children }) {
    state[listName] = children;
  },
  setChildrenCreateForm(state, { listName, value }) {
    state.createChildren[listName] = value;
  },
  setParentsList(state, { listName, parents }) {
    state[listName] = parents;
  },
  setParentInfos(state, { fieldName, parentInfos }) {
    state[fieldName] = parentInfos;
  },
  clearKindergartensChildren(state, { listName }) {
    state[listName].results = [];
  },
  clearCreateFormChildren(state, { fieldName }) {
    state[fieldName] = {
      name: null,
      children_group: null,
      number: null,
      parents: null
    };
  },

  clearCreateParentsForm(state, { listName }) {
    state[listName] = {
      phone_number: "",
      group: null,
      user: "",
      comments: "",
      selectedId: null
    };
  },
  changeEditChildren(state, { fieldName, value }) {
    state.editChildren[fieldName].value = value;
    state.editChildren[fieldName].isEdit = true;
  },
  prepareEditChildren(state, editChildren) {
    Object.keys(state.editChildren).forEach(key => {
      state.editChildren[key].value = editChildren[key];
      state.editChildren[key].isEdit = false;
    });
  },
  addParent(state, { number }) {
    state.createCardManualForm.cardNumbers.splice(0, 0, number);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
