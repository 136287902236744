import * as paymentsRequests from "@/helpers/api/payments";
import {
  getKindergartensPaymentsRequest,
  getPaymentsObjectRequest,
  getPaymentsRequest,
  getPaymentsRequestReport,
  refundSbpPaymentByIdRequest,
  refundPaymentByIdRequest
} from "@/helpers/api/payments";
import { csvExport } from "@/helpers/CsvHelper";

const state = {
  pagePaymentList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  pagePaymentIdList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  dealerPayments: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  userPayments: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  objectPayments: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  organizationPayments: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};

const getters = {
  getPagePaymentsList: state => state.pagePaymentList,
  getPagePaymentIdList: state => state.pagePaymentIdList,
  getDealerPayment: state => state.dealerPayments,
  getObjectPayments: state => state.objectPayments,
  getOrganizationPayments: state => state.organizationPayments,
  getUserPayments: state => state.userPayments
};

const actions = {
  async fetchPagePaymentsList(context) {
    const queries = context.getters.getUrlQueris;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      paymentsRequests
        .getPaymentsRequest({ query: { ordering, ...queries } })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "pagePaymentList",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchPageIdPaymentsList(context) {
    const queries = context.getters.getUrlQueris;
    const ordering = context.getters.getOrdering;
    const id = context.getters.getUserId;

    return new Promise((resolve, reject) => {
      paymentsRequests
        .getPaymentsRequest({
          query: {
            ordering,
            ...queries,
            user: id,
            status__in: ["reversed", "completed", "awa-card", "refunded"]
          }
        })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "pagePaymentList",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchPageKinderIdPaymentsList(context) {
    const queries = context.getters.getUrlQueris;
    const ordering = context.getters.getOrdering;
    const id = context.getters.getUserId;

    return new Promise((resolve, reject) => {
      paymentsRequests
        .getKindergartensPaymentsRequest({
          id: localStorage.getItem("organization"),
          query: {
            ordering,
            ...queries,
            user: id,
            status__in: ["reversed", "completed", "awa-card", "refunded"]
          }
        })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "pagePaymentList",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // async fetchPageIdPaymentsList(context) {
  //   const queries = context.getters.getUrlQueris;
  //   const ordering = context.getters.getOrdering;
  //   const id = context.getters.getUserId;
  //
  //   return new Promise((resolve, reject) => {
  //     paymentsRequests
  //       .getKindergartensPaymentsRequest({
  //         id: localStorage.getItem("organization"),
  //         query: {
  //           ordering,
  //           ...queries,
  //           user: id,
  //           status__in: ["reversed", "completed", "awa-card", "refunded"]
  //         }
  //       })
  //       .then(response => {
  //         context.commit("setPaymentList", {
  //           listName: "pagePaymentList",
  //           payments: response.data
  //         });
  //         resolve();
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },

  async fetchPagePaymentsIdList(context) {
    const queries = context.getters.getUrlQueris.query;
    const id = context.getters.getPageQuery.id;

    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      paymentsRequests
        .getPaymentByIdRequest({ id, query: { ordering, ...queries } })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "pagePaymentIdList",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  downloadPagePaymentsList(context, header) {
    const { query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsRequest({
        query: { ...query, limit: 5000, offset: null, ordering }
      })
        .then(response => {
          csvExport(response.data, header, "Платежи");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  downloadReportsPaymentsList(context) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getPaymentsRequestReport({
        query: { ...query, offset: null },
        data: {
          ...query
        }
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async downloadDealerPaymentsList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsRequest({
        query: { ...query, limit: 5000, offset: null, diller: id, ordering }
      })
        .then(response => {
          csvExport(response.data, header, "Платежи");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchDealerPaymentsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsRequest({ query: { ...query, diller: id, ordering } })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "dealerPayments",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchUserPaymentsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsRequest({ query: { ...query, ordering, user: id } })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "userPayments",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchUserPaymentsObjectList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsObjectRequest({
        query: { ...query, ordering, user: id },
        id: context.getters.getObjectInfo.results.id
      })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "userPayments",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchUserPaymentsKindergartensList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getKindergartensPaymentsRequest({
        query: { ...query, ordering, user: id },
        id:
          localStorage.getItem("organization") != 1
            ? localStorage.getItem("organization")
            : localStorage.getItem("KindergartenId")
      })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "userPayments",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadObjectPaymentsList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsObjectRequest({
        query: { ...query, limit: 5000, offset: null, object: id, ordering },
        id: query.object
      })
        .then(response => {
          csvExport(response.data, header, "Платежи");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectPaymentsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsObjectRequest({
        query: { ...query, ordering, object: id },
        id: id
      })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "objectPayments",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadPaymentsList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getKindergartensPaymentsRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null,
          organization: id,
          ordering
        },
        id: context.getters.getKindergartenId
      })
        .then(response => {
          csvExport(response.data, header, "Платежи");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationPaymentsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getPaymentsRequest({ query: { ...query, ordering, organization: id } })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "organizationPayments",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchKindergartensPaymentsList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getKindergartensPaymentsRequest({ query: { ...query, ordering }, id: id })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "organizationPayments",
            payments: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async refundsSBPPaymentsList(context) {
    const id = context.getters.getPageQuery.id;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      refundSbpPaymentByIdRequest({ query: { ordering }, id: id })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "organizationPayments",
            payments: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async refundsPaymentsList(context) {
    const id = context.getters.getPageQuery.id;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      refundPaymentByIdRequest({ query: { ordering }, id: id })
        .then(response => {
          context.commit("setPaymentList", {
            listName: "organizationPayments",
            payments: response.data
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async clearPaymentsPage(context) {
    context.commit("clearPaymentList", {
      listName: "pagePaymentList"
    });
    return Promise.resolve();
  }
};

const mutations = {
  setPaymentList(state, { listName, payments }) {
    state[listName] = payments;
  },
  clearPaymentList(state, { listName }) {
    state[listName].results = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
